import PropTypes from "prop-types"
import React from "react"

import HeaderTitle from "../components/header-title"
// import HeaderAddress from "../components/header-address"
import PageTitle from "../components/page-title"
import HeaderDate from "../components/header-date"
import HeaderSubtitle from "../components/header-subtitle"
import HeaderStrapline from "../components/header-strapline"
import PrimaryButton from "../components/primary-button"
import VenueImage from "./venue-image"
import BandsCircle from "./bands-circle"
import BandsRectangle from "./bands-rectangle"
import BannerSupportImage from "./banner-support-image"
import { Link } from "gatsby"
import HeadlineSponsors from "./headline-sponsors"
import PartnerCarousel from "../components/partner-carousel"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import SupportBanner from "./support-banner"
import RegisterInterest from "./register-interest"

const MainBanner = ({ text }) => (
  <div style={{
    position: `relative`,
    marginTop: `50px`
  }}>
    <div className="main-banner">
      <HeaderTitle />
      <HeaderSubtitle />
      <br />
      <HeaderStrapline />
      <br />
      {/* <HeaderDate text="17th and 18th September 2024" /> */}
      <div className="row" style={{
        maxWidth: `36rem`
      }}>
        <div className="col">
          <h4 style={{
            textTransform: `uppercase`,
            color: `#fff`,
            fontFamily: `Din Pro`,
            fontSize: `1.5rem`
          }}>Conference</h4>
          <p style={{
            textTransform: `uppercase`,
            color: `#fff`,
            fontFamily: `Din Pro`,
            fontSize: `1rem`
          }}>17th and 18th September 2024</p>
        </div>
        <div className="col">
          <h4 style={{
            textTransform: `uppercase`,
            color: `#fff`,
            fontFamily: `Din Pro`,
            fontSize: `1.5rem`
          }}>Awards Ceremony</h4>
          <p style={{
            textTransform: `uppercase`,
            color: `#fff`,
            fontFamily: `Din Pro`,
            fontSize: `1rem`
          }}>21st November 2024</p>
        </div>
      </div>
      
      {/* <div style={{ maxWidth: `380px`, marginTop: `3rem` }}><RegisterInterest /></div> */}
      {/* <p className="text-white">Entry Deadline Extended until Friday 9th December 2022</p> */}
      <br />
      {/* <HeaderAddress /> */}

      {/* <PrimaryButton to="/enter/" text="Start Your Entry" />&nbsp;&nbsp; */}
      {/* <Link to="/categories/" className="btn" style={{
        textTransform: `uppercase`,
        color: `#fff`,
        fontWeight: `bold`,
        border: `2px solid #fff`,
        padding: `0.6rem 1rem`,
        borderRadius: `0`
      }}>View the Categories</Link> */}

      <PrimaryButton to="/winners" text="Winners Announced" />&nbsp;&nbsp;
      {/* <a href="/shortlist" className="btn" style={{
        textTransform: `uppercase`,
        color: `#fff`,
        fontWeight: `bold`,
        border: `2px solid #fff`,
        padding: `0.6rem 1rem`,
        borderRadius: `0`
      }}>View the Shortlist</a> */}
      <Link to="/shortlist/" className="btn" style={{
        textTransform: `uppercase`,
        color: `#fff`,
        fontWeight: `bold`,
        border: `2px solid #fff`,
        padding: `0.6rem 1rem`,
        borderRadius: `0`
      }}>View the Shortlist</Link>
      
      {/* <PrimaryButton to="/winners/" text="View the Winners of 2021" /> */}
      {/* <PrimaryButton to="/shortlist/" text="View the Shortlist" />&nbsp;&nbsp;
      <Link to="/conference/" className="btn" style={{
        textTransform: `uppercase`,
        color: `#fff`,
        fontWeight: `bold`,
        border: `2px solid #fff`,
        padding: `0.6rem 1rem`,
        borderRadius: `0`
      }}>View the Conference</Link> */}
      {/* <OutboundLink href="https://www.bytes.co.uk/" target="_blank"><BannerSupportImage style={{maxWidth: `35rem`, margin: `2rem -.5rem`}} /></OutboundLink> */}
      <div className="mt-5"><SupportBanner /></div>
      <br /><br /><br /><br />
      {/* <PrimaryButton url="https://2023.smarterworkinglive.com" text="2023 Event" /> */}
    </div>
    {/* <VenueImage className="d-none d-lg-block" style={{
      width: `470px`,
      position: `absolute`,
      top: `0`,
      right: `0`,
      // zIndex: `-2`,
    }} /> */}
          <PageTitle text="Sponsors and Exhibitors" />
      <HeadlineSponsors />
    <div className="d-none d-lg-block" style={{
        position: `absolute`,
        top: `300px`,
        right: `130px`,
        width: `250px`,
        zIndex: `1000`
      }}>
      <PartnerCarousel></PartnerCarousel>
    </div>
    {/* <div style={{
      background: `linear-gradient(90deg, rgba(251,146,1,1) 0%, rgba(210,52,100,1) 25%)`,
      width: `450px`,
      height: `450px`,
      borderRadius: `50%`,
      position: `absolute`,
      top: `20px`,
      right: `-150px`,
      zIndex: `-3`
    }} /> */}
    {/* <BandsCircle size="450px" style={{
      position: `absolute`,
      top: `-20px`,
      right: `-180px`,
      zIndex: `-1`
    }} /> */}
    <div style={{
      background: `linear-gradient(90deg, rgba(251,146,1,1) 0%, rgba(210,52,100,1) 75%)`,
      width: `60px`,
      height: `60px`,
      borderRadius: `50%`,
      position: `absolute`,
      top: `100px`,
      right: `50px`,
      zIndex: `-3`
    }} />
  </div>
)

MainBanner.propTypes = {
  text: PropTypes.string,
}

MainBanner.defaultProps = {
  text: ``,
}

export default MainBanner